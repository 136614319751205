import { z } from 'zod'
export * from './errors'
export * from './links'
export * from './roles'

export const PUBLIC_KEY = 'public'
export const APIKEY_KEY = 'apikey'
export const PERMISSION_KEY = 'permissions'
export const TARGET_WORKSPACE_PERMISSION_KEY = 'target_workspace_permissions'
export const SHAREABLE_LINK_PERMISSION_KEY = 'shareable_link_permissions'

export enum MediaUploadStatus {
  STARTED = 'started',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  ERROR = 'error',
}

export enum FileType {
  PNG_MIME_TYPE = 'image/png',
  JPG_MIME_TYPE = 'image/jpg',
  JPEG_MIME_TYPE = 'image/jpeg',
  GIF_MIME_TYPE = 'image/gif',
  SVG_MIME_TYPE = 'image/svg+xml',
  MP4_MIME_TYPE = 'video/mp4',
  MOV_MIME_TYPE = 'video/quicktime',
  M4V_MIME_TYPE = 'video/x-m4v',
  XML_MIME_TYPE = 'application/xml',
}

export enum FormatName {
  JPEG_FORMAT_NAME = 'jpeg',
  JPG_FORMAT_NAME = 'jpg',
  PNG_FORMAT_NAME = 'png',
  GIF_FORMAT_NAME = 'gif',
  SVG_FORMAT_NAME = 'svg',
  MP4_FORMAT_NAME = 'mp4',
  MOV_FORMAT_NAME = 'mov',
  M4V_FORMAT_NAME = 'm4v',
}

export enum LogFormatName {
  ADDED_USER_NAME = 'added user to workspace',
  REMOVED_USER_NAME = 'removed user from workspace',
  UPLOADED_MEDIA_NAME = 'uploaded media',
  DELETED_MEDIA_NAME = 'deleted media',
  DOWNLOADED_MEDIA_NAME = 'downloaded media',
  COPIED_MEDIA_NAME = 'copied media',
}

export enum WorkspaceLogType {
  WORKSPACE_PERMISSIONS = 'Workspace permissions',
  UPLOADS = 'Uploads',
  COPIED_MEDIA = 'Copied media',
  DOWNLOADS = 'Downloads',
  DELETION = 'Deletion',
}

export const ALLOWED_IMAGE_MIME_TYPES: string[] = [
  FileType.PNG_MIME_TYPE,
  FileType.JPG_MIME_TYPE,
  FileType.JPEG_MIME_TYPE,
  FileType.GIF_MIME_TYPE,
  FileType.SVG_MIME_TYPE,
]

export const ALLOWED_VIDEO_MIME_TYPES: string[] = [
  FileType.MP4_MIME_TYPE,
  FileType.MOV_MIME_TYPE,
  FileType.M4V_MIME_TYPE,
]

export const ALLOWED_VIDEO_FORMATS: string[] = [
  FormatName.MP4_FORMAT_NAME,
  FormatName.MOV_FORMAT_NAME,
  FormatName.M4V_FORMAT_NAME,
]

// Image formats which can be sent to AWS Rekognition
export const IMAGE_FORMATS: string[] = [
  FormatName.JPG_FORMAT_NAME,
  FormatName.JPEG_FORMAT_NAME,
  FormatName.PNG_FORMAT_NAME,
]

// TODO: fetch allowed formats from format table in DB and map to respective IDs
export const ALLOWED_FILTER_FORMATS: string[] = [
  FormatName.PNG_FORMAT_NAME,
  FormatName.JPG_FORMAT_NAME,
  FormatName.GIF_FORMAT_NAME,
  FormatName.SVG_FORMAT_NAME,
  ...ALLOWED_VIDEO_FORMATS,
]
export const ALLOWED_FORMATS = Object.freeze([
  ...ALLOWED_FILTER_FORMATS.slice(0, 2),
  FormatName.JPEG_FORMAT_NAME, // inserting JPEG into index 2, since frontend copy uses this list
  ...ALLOWED_FILTER_FORMATS.slice(2),
])

export enum LinkType {
  INTERNAL = 'internal',
}

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
export const DEFAULT_ALBUM_NAME = 'Default'
export const DEFAULT_WORKSPACE_NAME = 'Personal'

export const MAX_TAG_LENGTH = 64

export const MAX_WORKSPACE_NAME_LENGTH = 48

export const THUMBNAIL_HEIGHT = 480

export const WORKSPACE_PATH_PREFIX = 'workspaces/:workspaceId/albums/:albumId'

export const VIDEO_THUMBNAIL_PLACEHOLDER = '/video-thumbnail-placeholder.gif'

export enum PermissionAction {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
  MANAGE = 'manage',
}

export type PermissionSubjectType = string

export type PermissionParam = [PermissionAction, PermissionSubjectType]

export enum ContextLocation {
  PARAMS = 'params',
  BODY = 'body',
  QUERY = 'query',
}

export type ContextType = `${ContextLocation}`

export type PermissionObject = {
  permissionParamList: PermissionParam[]
  workspaceContext: ContextType
}

export type TargetWorkspacePermissionObject = {
  sourceWorkspacePermissions: {
    permissions: PermissionParam[]
    workspaceContext?: ContextType
  }
  targetWorkspacePermissions: {
    permissions: PermissionParam[]
  }
}

export const CELEBRITY_FACE_THRESHOLD = 93 // in percentage

export const MAX_UPLOAD_AMOUNT = 100
export const MAX_TOTAL_UPLOAD_SIZE = 10000000000 // 10 GB (in B)
export const MAX_IMAGE_SIZE = 15000000 // 15 MB (in B)
export const AWS_S3_PART_SIZE = 50 * 1024 * 1024 // 50 MiB (in B), maximum allowed by AWS is 5GiB (5 * 1024 * 1024 * 1024 B).
export const AWS_S3_MAX_FILE_SIZE = AWS_S3_PART_SIZE * 10000 //~50 GiB (in B) maximum number of parts is 10,000 max object size is 50 TiB
export const PART_UPLOAD_CONCURRENCY_LIMIT = 6 //based on browser limits https://docs.diffusiondata.com/cloud/latest/manual/html/designguide/solution/support/connection_limitations.html
export const VIDEO_THUMBNAIL_GENERATION_TIMEOUT = 10000 // 10s (in ms)
export const MAX_MULTIPLE_DOWNLOAD_SIZE = 300000000 //300 MB (in B)

export const MAGIC_NUMBER_BYTES = 4100 // minimum number of bytes for file-type library

export const SessionUserEmailSchema = z.object({
  user: z.object({
    email: z.string(),
  }),
})
