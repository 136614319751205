import { Box, Divider, Flex, Link, Stack, Text, Wrap } from '@chakra-ui/react'
import { useIsMobile } from '@opengovsg/design-system-react'

import {
  DEFAULT_FOOTER_ICON_LINKS,
  DEFAULT_SOCIAL_MEDIA_LINKS,
} from './common/constants'
import {
  FooterContainerProps,
  FooterLinkWithIconAndHeader,
  FooterVariantProps,
} from './common/types'

export const FullFooter = ({
  appName,
  appLink,
  tagline,
  footerLinks,
  footerIconLinks = DEFAULT_FOOTER_ICON_LINKS,
  socialMediaLinks = DEFAULT_SOCIAL_MEDIA_LINKS,
  containerProps,
}: FooterVariantProps): JSX.Element => {
  const currentYear = new Date().getFullYear()
  const isMobile = useIsMobile()

  return (
    <FullFooter.Container {...containerProps}>
      <FullFooter.Section my="1.5rem">
        <Stack
          flex={1}
          direction="column"
          paddingEnd={{ base: 0, lg: '1.5rem' }}
          align="baseline"
        >
          <Link
            variant="standalone"
            m="-0.5rem"
            isExternal
            href={appLink}
            p="0"
            color="base.content.strong"
          >
            <Text textStyle="h4">{appName}</Text>
          </Link>
          {!isMobile && <Text textStyle="caption-2">{tagline}</Text>}

          <Wrap
            flex={1}
            shouldWrapChildren
            spacing={{ base: '1rem', md: '1.25rem' }}
            direction={{ base: 'column', md: 'row' }}
            justify={{ base: 'normal', md: 'flex-end' }}
            pt="1.5rem"
          >
            {footerLinks?.map(({ label, href }, index) => (
              <Link
                isExternal
                m="-0.25rem"
                key={index}
                variant="standalone"
                w="fit-content"
                href={href}
                textStyle="caption-2"
                color="base.content.strong"
              >
                {label}
              </Link>
            ))}
          </Wrap>
        </Stack>
      </FullFooter.Section>

      {isMobile && <Divider my="1rem" bg="base.divider.strong" />}

      <FullFooter.Section my="1.5rem">
        <Flex flexDir="row" gap="2.25rem">
          {footerIconLinks?.map((footerIconLink, index) => (
            <FooterOrganisationLogo
              footerIconLink={footerIconLink}
              key={index}
            />
          ))}
        </Flex>

        <Box>
          <Stack
            spacing="1rem"
            direction="row"
            mt={{ base: 0, lg: '2rem' }}
            mb="0.5rem"
            justify={{ base: 'normal', lg: 'flex-end' }}
          >
            {socialMediaLinks?.map(({ label, href, Icon }, index) => (
              <Link key={index} isExternal title={label} w="2rem" href={href}>
                <Icon />
              </Link>
            ))}
          </Stack>
          <Flex
            align={{ base: 'start', lg: 'end' }}
            flexDir="column"
            gap={{ base: '1rem', lg: '0.25rem' }}
          >
            <Text textStyle="legal">
              ©{currentYear} Digital Ops-Tech Centre, a member of the Digital
              and Intelligence Service
            </Text>
            <Text textStyle="legal">
              Singapore Armed Forces | Ministry of Defence Singapore
            </Text>
          </Flex>
        </Box>
      </FullFooter.Section>
    </FullFooter.Container>
  )
}

FullFooter.Container = function FullFooterContainer({
  children,
  ...props
}: FooterContainerProps): JSX.Element {
  return (
    <Flex
      as="footer"
      flexDirection="column"
      px={{ base: '1.5rem', md: '5.5rem', lg: '9.25rem' }}
      {...props}
    >
      {children}
    </Flex>
  )
}

FullFooter.Section = function FullFooterSection({
  children,
  ...props
}: FooterContainerProps): JSX.Element {
  return (
    <Flex
      align={{ base: 'normal', lg: 'center' }}
      flex={1}
      justify="space-between"
      flexDir={{ base: 'column', lg: 'row' }}
      {...props}
    >
      {children}
    </Flex>
  )
}

const FooterOrganisationLogo = ({
  footerIconLink,
}: {
  footerIconLink: FooterLinkWithIconAndHeader
}) => {
  return (
    <Box>
      <Text textStyle="caption-1" mb="0.5rem">
        {footerIconLink.header}
      </Text>
      <Wrap
        flex={1}
        shouldWrapChildren
        textStyle="body-2"
        spacing={{ base: '1rem', lg: '1.25rem' }}
        direction={{ base: 'row' }}
        justify={{ base: 'normal', lg: 'flex-end' }}
        align={{ base: 'flex-end' }}
      >
        <Link
          isExternal
          title={footerIconLink.label}
          mb="2rem"
          color="base.content.strong"
          href={footerIconLink.href}
        >
          <footerIconLink.Icon height="3rem" width="auto" />
        </Link>
      </Wrap>
    </Box>
  )
}
